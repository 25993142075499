@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@keyframes twinkle {
  0%, 100% { opacity: 0.2; }
  50% { opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes spin-slow {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.animate-twinkle {
  animation: twinkle var(--tw-animation-duration) infinite;
}

.animate-float {
  animation: float var(--tw-animation-duration) infinite;
}

.animate-spin-slow {
  animation: spin-slow 20s linear infinite;
}
